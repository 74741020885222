import * as GQL from "@/gql";

export function isSuppressedInLocale(
  contentPage: { suppressedLocales?: GQL.Maybe<string> },
  locale: string
) {
  const suppressedLocales = (contentPage.suppressedLocales ?? "")
    .split(",")
    .map((x) => x.trim());
  return suppressedLocales.includes(locale);
}
