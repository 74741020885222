import * as GQL from "@/gql";
import { LinkProps } from "next/link";
import { contentPageLinkProps } from "../../lib/links";
import { allContentPages } from "./data";

export { allContentPages, contentInfo } from "./data";

/**
 * This function generates the data for the menu structure, that is:
 * All top-level content pages plus their direct children & grandchildren (menu is only
 * two levels deep). Only non-hidden pages, enabled in the selected locale
 * are included.
 */
export const menuItems = (locale: string) => {
  return allContentPages.flatMap((contentPage) => {
    if (contentPage.hidden || GQL.isSuppressedInLocale(contentPage, locale)) {
      return [];
    }

    const { _allSlugLocales, _allPageNameLocales } = contentPage;

    const slug = _allSlugLocales?.find((x) => x?.locale === locale);
    const pageName = _allPageNameLocales?.find((x) => x?.locale === locale);

    if (!slug || !pageName) {
      return [];
    }

    const children =
      contentPage.children
        ?.flatMap((d) => (d?.children ? [d, ...d.children] : [d]))
        .flatMap((child) => {
          if (!child || child.hidden || GQL.isSuppressedInLocale(child, locale)) {
            return [];
          }

          const { _allSlugLocales, _allPageNameLocales } = child;

          const slug = _allSlugLocales?.find((x) => x?.locale === locale);
          const pageName = _allPageNameLocales?.find((x) => x?.locale === locale);

          if (!slug || !pageName) {
            return [];
          }

          return [
            {
              ...child,
              slug: slug.value,
              label: pageName.value,
            },
          ];
        }) ?? [];

    return [
      {
        ...contentPage,
        slug: slug.value,
        label: pageName.value,
        children,
      },
    ];
  });
};

interface ContentPageLocatorFragment extends GQL.ContentPageLocatorFragment {
  children?: GQL.Maybe<Array<undefined | GQL.Maybe<GQL.ContentPageLocatorFragment>>>;
}

/**
 * Return the full path to the page (identified by its slug).
 *
 * eg. contentPagePath("de", "skills40") == ["news-events", "skills40"]
 */
export const contentPagePath = (locale: string, id: string): undefined | string[] => {
  const go =
    (page: string[]) =>
    (contentPages: Array<undefined | GQL.Maybe<ContentPageLocatorFragment>>): undefined | string[] => {
      if (!contentPages) {
        return undefined;
      }

      for (const contentPage of contentPages) {
        if (contentPage) {
          const s = contentPage._allSlugLocales?.find((x) => x?.locale === locale);

          if (s) {
            if (contentPage.id === id) {
              return [...page, s.value!];
            }

            const { children } = contentPage;

            if (children) {
              const match = go([...page, s.value!])(children);
              if (match) {
                return match;
              }
            }
          }
        }
      }

      return undefined;
    };

  return go([])(allContentPages);
};

/**
 * Return the breadcrumbs to the Content Page. The breadcrumbs are defined as all
 * parent pages, excluding the page itself.
 *
 * If 'includePage' is true, then the target page is included in the breadcrumbs.
 */
export const breadcrumbs = (locale: string, id: string, { includePage }: { includePage?: boolean } = {}) => {
  const go =
    (path: Array<{ label: string; linkProps: LinkProps; isVirtual: boolean }>) =>
    (
      contentPages: Array<undefined | GQL.Maybe<ContentPageLocatorFragment>>
    ): undefined | Array<{ label: string; linkProps: LinkProps; isVirtual: boolean }> => {
      if (!contentPages) {
        return undefined;
      }

      for (const contentPage of contentPages) {
        if (contentPage) {
          const s = contentPage._allPageNameLocales?.find((x) => x?.locale === locale);

          if (s) {
            if (contentPage.id === id) {
              if (includePage) {
                return [
                  ...path,
                  {
                    label: s.value!,
                    linkProps: contentPageLinkProps(locale, contentPage),
                    isVirtual: contentPage.isVirtual!,
                  },
                ];
              } else {
                return path;
              }
            }

            const { children } = contentPage;

            if (children) {
              const match = go([
                ...path,
                {
                  label: s.value!,
                  linkProps: contentPageLinkProps(locale, contentPage),
                  isVirtual: contentPage.isVirtual!,
                },
              ])(children);

              if (match) {
                return match;
              }
            }
          }
        }
      }

      return undefined;
    };

  return go([])(allContentPages) ?? [];
};

export const contentPageAtPath = (path: string) => {
  const [, , ...segments] = path.split("/");

  const go =
    (head?: string, ...rest: string[]) =>
    (
      contentPages: Array<undefined | GQL.Maybe<ContentPageLocatorFragment>>
    ): undefined | (typeof allContentPages)[number] => {
      if (!head || !contentPages) {
        return undefined;
      }

      for (const contentPage of contentPages) {
        if (contentPage) {
          if (contentPage._allSlugLocales?.some((x) => x?.value === head)) {
            if (rest.length === 0) {
              return { ...contentPage, children: (contentPage.children ?? []) as any[] };
            } else {
              return go(...rest)(contentPage.children || []);
            }
          }
        }
      }

      return undefined;
    };

  return go(...segments)(allContentPages);
};
