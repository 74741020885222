import { contentPagePath } from "@/cms/ContentPageStructure";
import * as GQL from "@/gql";
import { ContentPageFragment } from "@/gql";
import { linkProps } from "@/routes";
import { LinkProps } from "next/link";

export const homeLinkProps = linkProps("", {});

export const contentPageLinkProps = (
  locale: string,
  {
    id,
    tweak,
    hash,
    _allNativeLocales,
  }: {
    id: string;
    tweak?: string | null;
    _allNativeLocales?: GQL.ContentPageLocatorFragment["_allNativeLocales"];
    hash?: string | null;
  }
): LinkProps & { as: string } => {
  const native = _allNativeLocales?.find((d) => d?.locale === locale)?.value;

  if (native && !tweak) {
    /*
     * This is required so that the path to the index page is correct. The following
     * should hold at all times:
     *
     * > homeLinkProps(locale) === contentPageLinkProps(locale, { id: <Index Page ID> })
     */
    const path = native === "index" ? "" : `${native}`;

    return {
      locale,
      href: {
        hash,
        pathname: `/${path}`,
      },
      as: hash ? `/${locale}/${path}#${hash}` : `/${locale}/${path}`,
    };
  }

  const page = contentPagePath(locale, id);
  if (!page) {
    return { href: { pathname: "/404" }, as: `/${locale}/404` };
  }

  return {
    locale,
    href: {
      hash,
      pathname: tweak ? `/_render/ContentPage/[id]/${tweak}` : "/_render/ContentPage/[id]",
      query: { id },
    },
    as: hash ? `/${locale}/${page.join("/")}#${hash}` : `/${locale}/${page.join("/")}`,
  };
};

function getParentPath(locale: string, ob: NonNullable<ContentPageFragment["path"]>) {
  const go =
    (page: string[]) =>
    (current: NonNullable<ContentPageFragment["path"]>): undefined | string[] => {
      const s = current._allSlugLocales?.find((x) => x?.locale === locale);
      if (s) {
        if (current.parent) {
          const match = go([...page, s.value!])(current.parent);

          if (match) {
            return match;
          }
        }
        return [...page, s.value!];
      }
      return undefined;
    };
  return go([])(ob);
}

export const slugLinkProps = (
  locale: string,
  {
    id,
    _allNativeLocales,
    value,
    key = "slug",
  }: {
    id: string;
    _allNativeLocales?: GQL.ContentPageLocatorFragment["_allNativeLocales"];
    value: string;
    key?: string;
  }
) => {
  const parentLinkProps = contentPageLinkProps(locale, { id, _allNativeLocales });

  if (parentLinkProps.as === `${locale}/404`) {
    return parentLinkProps;
  }

  const cleanedParentHref = parentLinkProps.as.slice(1);
  return linkProps(`${cleanedParentHref}/[${key}]` as any, { [key]: value });
};

export function contentPageParentLinkProps(locale: string, contentPage: ContentPageFragment) {
  return `/${locale}/${getParentPath(locale, {
    __typename: "ContentPageRecord",
    id: contentPage.id,
    _allSlugLocales: contentPage._allSlugLocales,
    parent: contentPage.path,
  })
    ?.reverse()
    .join("/")}`;
}
