import { ParsedUrlQuery, stringify } from "querystring";

interface Routes {
  "404": {};
  _error: {};
  "_render/Announcement/[id]": {
    id: string;
  };
  "_render/BlogPost/[id]": {
    id: string;
  };
  "_render/ContentPage/[id]/bfe-calculator": {
    id: string;
  };
  "_render/ContentPage/[id]/blog": {
    id: string;
  };
  "_render/ContentPage/[id]": {
    id: string;
  };
  "_render/KvPost/[id]": {
    id: string;
  };
  "_system/headers": {};
  "_system/image": {};
  "_system/markdown": {};
  "_system/preview": {};
  "_system/sitemap": {};
  blog: {};
  "cyp/medien": {};
  "cyp/teams": {};
  "docs/components/[component]": {};
  "docs/components/[component]/samples/[sample]": {};
  "docs/theme/typography/text": {};
  "feed.xml": {};
  "": {};
  lobby: {};
  "news-events/events": {};
  "news-events/kv-2022": {};
  "news-events/news": {};
  unsupported: {};
}

/**
 * Usage:
 *
 *   const { href, as } = linkProps("products/[productId]", { produtId: "xyz" })
 *
 *   <Link {...linkProps(…)} passHref>
 *     <a>…</a>
 *   </Link>
 */
export const linkProps = <R extends keyof Routes>(pathname: R, query: Routes[R], options?: { locale?: string }) =>
  ({
    href: {
      pathname: "/" + pathname,
      query,
    },
    as: (() => {
      const regularQueryParams: ParsedUrlQuery = {};

      const dynamicParamRe = /\[(\w+)\]/g;
      const dynamicParams: Set<string> = new Set();
      let match: RegExpExecArray | null;
      while ((match = dynamicParamRe.exec(pathname))) {
        dynamicParams.add(match[1]);
      }

      let asPath = "/" + pathname;
      for (const [k, v] of Object.entries(query)) {
        if (dynamicParams.has(k)) {
          asPath = asPath.replace(`[${k}]`, v!.toString());
        } else if (v != null && v !== "") {
          regularQueryParams[k] = v;
        }
      }

      if (Object.keys(regularQueryParams).length > 0) {
        asPath = `${asPath}?${stringify(regularQueryParams)}`;
      }

      return options?.locale ? `/${options.locale}${asPath}` : asPath;
    })(),
  }) as const;
